import { useContext, useState } from "react"
import { ContentTile } from "../Components/ModeTile"
import { TextStyle } from "../App"
import TextArea from "antd/es/input/TextArea"
import { SetMenu } from "../Loaders/LanguageDefinitionLoader"
import { OnExit } from "../Types/Interfaces"
import { ColumnLayout, RowLayout } from "../Styles/Styles"
import { useQuery } from "@tanstack/react-query"
import { ApiContext } from "../Contexts/Api/ApiContext"
import { UnwrapApiCall } from "../Contexts/Api/DefaultApiContext"
import { ExampleSentenceDisplay } from "../Components/ItemExamplesView"
import { LoadDictionaryQuery } from "./DictionaryView"

export const BreakdownTextView: React.FC<SetMenu & OnExit> = props => {


    const [text, setText] = useState("")
    const [submitted, setSubmitted] = useState(false)

    const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setText(processText(e.target.value))
    }

    const processText = (text: string) => {
        return text.replace(/[\s\n]/g, '')
    }

    const handleBreakdown = () => {
        setSubmitted(true)
    }

    const handleClear = () => {
        setText("")
        setSubmitted(false)
    }

    const handlePaste = async () => {
        try {
            const clipboardText = await navigator.clipboard.readText();
            setText(processText(clipboardText));
            setSubmitted(true)
        } catch (err) {
            console.error('Failed to read clipboard:', err);
        }
    }

    const handleAppend = async () => {
        try {
            const clipboardText = await navigator.clipboard.readText();
            setText(processText(text + clipboardText));
            setSubmitted(true)
        } catch (err) {
            console.error('Failed to read clipboard:', err);
        }
    }

    return <>
        <ContentTile>

            <ColumnLayout>
                <TextStyle size={2}>Breakdown Text</TextStyle>
                <TextArea value={text} rows={4} onChange={handleTextChange} />
                <RowLayout>
                    <input style={{ width: "100px" }} type="button" value="Breakdown" onClick={handleBreakdown} />
                    <input style={{ width: "100px" }} type="button" value="Clear" onClick={handleClear} />
                    <input style={{ width: "100px" }} type="button" value="Paste" onClick={handlePaste} />
                    <input style={{ width: "100px" }} type="button" value="Append" onClick={handleAppend} />
                </RowLayout>
            </ColumnLayout>
            <ColumnLayout>
                {submitted && <LoadTextBreakdown text={text} />}
            </ColumnLayout>
        </ContentTile>
    </>
}

const LoadTextBreakdown: React.FC<{ text: string }> = props => {
    const api = useContext(ApiContext)


    const { isPending, error, data } = useQuery({
        queryKey: ["textBreakdown", props.text],
        queryFn: () => {
            return api.BreakdownText({ text: props.text }).then(UnwrapApiCall)
        },
        gcTime: 0,
        staleTime: Infinity
    })

    if (isPending) return <>Loading...</>
    if (error) return <>An error has occurred: {error.message}</>
    if (data == undefined) return <>An error has occurred: { }</>


    return <>
        <ColumnLayout>
            <ExampleSentenceDisplay text={data.text} sentenceRef={data.ref} segments={data.segments} />
            <LoadDictionaryQuery query={data.text} />
        </ColumnLayout>
    </>
}
