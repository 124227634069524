import React, { useContext, useState } from 'react';
import { ColumnLayout, InnerTile, RowLayout } from '../Styles/Styles';
import { ContentTile, StyledButton } from '../Components/ModeTile';
import { TextStyle } from '../App';
import { SentenceSearchKey } from '../api/models/SentenceSearchKey';
import { SentenceKeyLastSeen, SentenceSearchType, SentenceSelectionMethod, SentenceSelectionQuery } from '../api';
import { UnwrapApiCall } from '../Contexts/Api/DefaultApiContext';
import { useQuery } from '@tanstack/react-query';
import { ApiContext } from '../Contexts/Api/ApiContext';
import { Modal } from '../Components/Modal';
import { DictionaryQueryTile } from '../Displays/ProcessedTextDisplay';

export const HistorySearchView: React.FC = () => {

    const [showItemData, setShowItemData] = useState(false)

    const [baseUnits, setBaseUnits] = useState<SentenceSearchKey>(SentenceSearchKey.New)
    const [lastSeen, setLastSeen] = useState<SentenceKeyLastSeen>(SentenceKeyLastSeen.Today)

    const Show = () => {
        setShowItemData(true)
    }

    return (
        <ContentTile>
            <ColumnLayout>

                <RowLayout>
                    <TextStyle>Search Key</TextStyle>
                    <select value={baseUnits} onChange={(e) => setBaseUnits(e.target.value as unknown as SentenceSearchKey)}>
                        <option value={SentenceSearchKey.New}>New</option>
                        <option value={SentenceSearchKey.Reviewed}>Reviewed</option>
                        <option value={SentenceSearchKey.Lapsed}>Lapsed</option>
                        <option value={SentenceSearchKey.Custom}>Custom</option>
                    </select>
                </RowLayout>

                <RowLayout>
                    <TextStyle>Last Seen</TextStyle>
                    <select value={lastSeen} onChange={(e) => setLastSeen(e.target.value as unknown as SentenceKeyLastSeen)}>
                        <option value={SentenceKeyLastSeen.Today}>Today</option>
                        <option value={SentenceKeyLastSeen.Yesterday}>Yesterday</option>
                        <option value={SentenceKeyLastSeen.ThisPeriod}>This period</option>
                        <option value={SentenceKeyLastSeen.LastPeriod}>Last period</option>
                        <option value={SentenceKeyLastSeen.AnyTime}>Any time</option>
                    </select>
                </RowLayout>


                <StyledButton onClick={Show}>Search</StyledButton>
            </ColumnLayout>
            {showItemData && <LoadSearchKeysDisplay baseUnits={baseUnits} lastSeen={lastSeen} />}


        </ContentTile >
    );
};

const LoadSearchKeysDisplay: React.FC<{ baseUnits: SentenceSearchKey, lastSeen: SentenceKeyLastSeen }> = (props) => {
    const api = useContext(ApiContext)


    const { isPending, error, data } = useQuery({
        queryKey: ["search-keys", props.baseUnits, props.lastSeen],
        queryFn: () => {
            const query: SentenceSelectionQuery = {
                selectionMethod: SentenceSelectionMethod.Recent,
                count: 0,
                baseUnits: props.baseUnits,
                lastSeen: props.lastSeen,
                sentenceType: SentenceSearchType.AllKnown,
                keyInput: [],
            }
            return api.SearchKeys(query).then(UnwrapApiCall)
        },
        gcTime: 0,
        staleTime: Infinity
    })

    if (isPending) return <>Loading...</>
    if (error) return <>An error has occurred: {error.message}</>
    if (data == undefined) return <>An error has occurred: { }</>

    return <>
        <ColumnLayout>
            <RowLayout>
                {data.map((key) => {
                    return <DictionaryQueryTile Text={key} />
                })}
            </RowLayout>
        </ColumnLayout>
    </>
}

