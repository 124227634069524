/**
 * main-srv
 * primary server for app
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { SentenceKeyLastSeen } from '../models/SentenceKeyLastSeen';
import { SentenceSearchKey } from '../models/SentenceSearchKey';
import { SentenceSearchType } from '../models/SentenceSearchType';
import { SentenceSelectionMethod } from '../models/SentenceSelectionMethod';
import { HttpFile } from '../http/http';

export class SentenceSelectionQuery {
    'count': number;
    'keyInput': Array<string>;
    'sentenceType': SentenceSearchType;
    'lastSeen': SentenceKeyLastSeen;
    'baseUnits': SentenceSearchKey;
    'selectionMethod': SentenceSelectionMethod;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "count",
            "baseName": "count",
            "type": "number",
            "format": "double"
        },
        {
            "name": "keyInput",
            "baseName": "keyInput",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "sentenceType",
            "baseName": "sentenceType",
            "type": "SentenceSearchType",
            "format": ""
        },
        {
            "name": "lastSeen",
            "baseName": "lastSeen",
            "type": "SentenceKeyLastSeen",
            "format": ""
        },
        {
            "name": "baseUnits",
            "baseName": "baseUnits",
            "type": "SentenceSearchKey",
            "format": ""
        },
        {
            "name": "selectionMethod",
            "baseName": "selectionMethod",
            "type": "SentenceSelectionMethod",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return SentenceSelectionQuery.attributeTypeMap;
    }

    public constructor() {
    }
}



