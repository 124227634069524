import React, { useContext, useEffect, useState } from 'react';
import { ColumnLayout, InnerTile, RowLayout } from '../Styles/Styles';
import { ContentTile, StyledButton } from '../Components/ModeTile';
import { TextStyle } from '../App';
import { SentenceSearchKey } from '../api/models/SentenceSearchKey';
import { SentenceKeyLastSeen, SentenceSearchType, SentenceSelectionMethod, SentenceSelectionQuery } from '../api';
import { UnwrapApiCall } from '../Contexts/Api/DefaultApiContext';
import { useQuery } from '@tanstack/react-query';
import { ApiContext } from '../Contexts/Api/ApiContext';
import { Modal } from '../Components/Modal';
import { DictionaryQueryTile } from '../Displays/ProcessedTextDisplay';

export const WriteSentencesView: React.FC = () => {

    const [showItemData, setShowItemData] = useState(false)

    const [count, setCount] = useState(10)
    const [baseUnits, setBaseUnits] = useState<SentenceSearchKey>(SentenceSearchKey.New)
    const [lastSeen, setLastSeen] = useState<SentenceKeyLastSeen>(SentenceKeyLastSeen.Today)

    const Show = () => {
        setShowItemData(true)
    }

    return (
        <ContentTile>
            <ColumnLayout>
                <RowLayout>
                    <TextStyle>Count</TextStyle>
                    <input type="number" value={count} onChange={(e) => setCount(e.target.value as unknown as number)} />
                </RowLayout>
                <RowLayout>
                    <TextStyle>Search Key</TextStyle>
                    <select value={baseUnits} onChange={(e) => setBaseUnits(e.target.value as unknown as SentenceSearchKey)}>
                        <option value={SentenceSearchKey.New}>New</option>
                        <option value={SentenceSearchKey.Reviewed}>Reviewed</option>
                        <option value={SentenceSearchKey.Lapsed}>Lapsed</option>
                        <option value={SentenceSearchKey.Custom}>Custom</option>
                    </select>
                </RowLayout>

                <RowLayout>
                    <TextStyle>Last Seen</TextStyle>
                    <select value={lastSeen} onChange={(e) => setLastSeen(e.target.value as unknown as SentenceKeyLastSeen)}>
                        <option value={SentenceKeyLastSeen.Today}>Today</option>
                        <option value={SentenceKeyLastSeen.Yesterday}>Yesterday</option>
                        <option value={SentenceKeyLastSeen.ThisPeriod}>This period</option>
                        <option value={SentenceKeyLastSeen.LastPeriod}>Last period</option>
                        <option value={SentenceKeyLastSeen.AnyTime}>Any time</option>
                    </select>
                </RowLayout>


                <StyledButton onClick={Show}>Search</StyledButton>
            </ColumnLayout>
            {showItemData && <LoadSearchKeysDisplay count={count} baseUnits={baseUnits} lastSeen={lastSeen} />}


        </ContentTile >
    );
};

const LoadSearchKeysDisplay: React.FC<{ count: number, baseUnits: SentenceSearchKey, lastSeen: SentenceKeyLastSeen }> = (props) => {
    const api = useContext(ApiContext)

    const [refreshToken, setRefreshToken] = useState(0)

    const { isPending, error, data } = useQuery({
        queryKey: ["search-keys", props.baseUnits, props.lastSeen, refreshToken],
        queryFn: () => {
            const query: SentenceSelectionQuery = {
                selectionMethod: SentenceSelectionMethod.Recent,
                count: 0,
                baseUnits: props.baseUnits,
                lastSeen: props.lastSeen,
                sentenceType: SentenceSearchType.AllKnown,
                keyInput: [],
            }
            return api.SearchKeys(query).then(UnwrapApiCall)
        },
        gcTime: 0,
        staleTime: Infinity
    })

    if (isPending) return <>Loading...</>
    if (error) return <>An error has occurred: {error.message}</>
    if (data == undefined) return <>An error has occurred: { }</>

    return <>
        <ColumnLayout>
            <RowLayout>
                <WriteSentencesInterface count={props.count} keys={data} />
            </RowLayout>

            <RowLayout>
                <StyledButton onClick={() => { setRefreshToken(refreshToken + 1) }}>Refresh</StyledButton>
            </RowLayout>
        </ColumnLayout>
    </>
}

const WriteSentencesInterface: React.FC<{  count: number, keys: string[] }> = (props) => {

    const [selectedKeys, setSelectedKeys] = useState<string[]>([])

    const [current, setCurrent] = useState(0)

    useEffect(() => {
        // Create copy of keys array to avoid modifying original
        const keysCopy = [...props.keys]

        // Shuffle array using Fisher-Yates algorithm
        for (let i = keysCopy.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [keysCopy[i], keysCopy[j]] = [keysCopy[j], keysCopy[i]]
        }

        // Take first n elements where n is count
        setSelectedKeys(keysCopy.slice(0, props.count))
    }, [props.keys, props.count])

    console.log(selectedKeys)
    console.log(current)
    console.log(selectedKeys[current])

    return <>
        {selectedKeys.length > 0 && <>
            {current < selectedKeys.length && <WriteSentencesTile next={() => { setCurrent(current + 1) }} word={selectedKeys[current]} />}
            {current >= selectedKeys.length && <>
                <TextStyle>Finished</TextStyle>
            </>}
        </>}
    </>
}


const WriteSentencesTile: React.FC<{ next: () => void, word: string }> = (props) => {

    const [sentence, setSentence] = useState('')
    const [submitted, setSubmitted] = useState(false)

    const updateSentence = (value: string) => {
        setSentence(value)
        setSubmitted(false)
    }


    const next = () => {
        setSentence('')
        setSubmitted(false)
        props.next()
    }

    return <>
        <ContentTile>
            <ColumnLayout>
                <RowLayout>
                    <TextStyle size={2}>{props.word}</TextStyle>
                </RowLayout>
                <RowLayout>
                    <input type="text" value={sentence} onChange={(e) => updateSentence(e.target.value)} />
                </RowLayout>
                <RowLayout>
                    {submitted && <EvaluateSentenceLoader word={props.word} sentence={sentence} />}
                </RowLayout>    
                <RowLayout>
                    <StyledButton onClick={() => { setSubmitted(true) }}>Submit</StyledButton>
                    <StyledButton onClick={next}>Next</StyledButton>
                </RowLayout>
            </ColumnLayout>
        </ContentTile>
    </>
}

const EvaluateSentenceLoader : React.FC<{word: string, sentence: string}> = (props) => {

    const api = useContext(ApiContext)

    const [refreshToken, setRefreshToken] = useState(0)

    const { isPending, error, data } = useQuery({
        queryKey: ["grade-sentence-construction", props.word, props.sentence, refreshToken],
        queryFn: () => {
            return api.GradeSentenceConstruction(props.word, props.sentence).then(UnwrapApiCall)
        },
        gcTime: 0,
        staleTime: Infinity
    })

    if (isPending) return <>Loading...</>
    if (error) return <>An error has occurred: {error.message}</>
    if (data == undefined) return <>An error has occurred: { }</>

    
    return <>
        <ColumnLayout>
            {data != 1 && <TextStyle size={1}>🔴 Incorrect</TextStyle>}
            {data == 1 && <TextStyle size={1}>🟢 Correct</TextStyle>}
            <RowLayout> 
                <DictionaryQueryTile Text={props.word} />
            </RowLayout>
        </ColumnLayout>
    </>
}
