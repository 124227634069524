/**
 * main-srv
 * primary server for app
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class KeyRecordData {
    'writeResponse': number;
    'writePrompt': number;
    'readPrompt': number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "writeResponse",
            "baseName": "writeResponse",
            "type": "number",
            "format": "double"
        },
        {
            "name": "writePrompt",
            "baseName": "writePrompt",
            "type": "number",
            "format": "double"
        },
        {
            "name": "readPrompt",
            "baseName": "readPrompt",
            "type": "number",
            "format": "double"
        }    ];

    static getAttributeTypeMap() {
        return KeyRecordData.attributeTypeMap;
    }

    public constructor() {
    }
}

