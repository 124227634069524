/**
 * main-srv
 * primary server for app
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { RecordSentenceKeyLastSeenKeyRecordStats } from '../models/RecordSentenceKeyLastSeenKeyRecordStats';
import { HttpFile } from '../http/http';

/**
* Construct a type with a set of properties K of type T
*/
export class RecordSentenceSearchKeyRecordSentenceKeyLastSeenKeyRecordStats {
    'lapsed': RecordSentenceKeyLastSeenKeyRecordStats;
    '_new': RecordSentenceKeyLastSeenKeyRecordStats;
    'reviewed': RecordSentenceKeyLastSeenKeyRecordStats;
    'custom': RecordSentenceKeyLastSeenKeyRecordStats;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "lapsed",
            "baseName": "lapsed",
            "type": "RecordSentenceKeyLastSeenKeyRecordStats",
            "format": ""
        },
        {
            "name": "_new",
            "baseName": "new",
            "type": "RecordSentenceKeyLastSeenKeyRecordStats",
            "format": ""
        },
        {
            "name": "reviewed",
            "baseName": "reviewed",
            "type": "RecordSentenceKeyLastSeenKeyRecordStats",
            "format": ""
        },
        {
            "name": "custom",
            "baseName": "custom",
            "type": "RecordSentenceKeyLastSeenKeyRecordStats",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return RecordSentenceSearchKeyRecordSentenceKeyLastSeenKeyRecordStats.attributeTypeMap;
    }

    public constructor() {
    }
}

