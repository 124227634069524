/**
 * main-srv
 * primary server for app
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export enum ActionRecordAction {
    StudyItems = 'StudyItems',
    IntroduceItem = 'IntroduceItem',
    ReviewItem = 'ReviewItem',
    LoadSentenceSelection = 'LoadSentenceSelection',
    ReadSentence = 'ReadSentence',
    FinishReadSentence = 'FinishReadSentence',
    DictateSentence = 'DictateSentence',
    FinishDictateSentence = 'FinishDictateSentence',
    GenerateText = 'GenerateText',
    UploadText = 'UploadText',
    OpenText = 'OpenText',
    ReadText = 'ReadText',
    InitiateChat = 'InitiateChat',
    QueryText = 'QueryText',
    WriteSentence = 'WriteSentence',
    FinishWriteSentence = 'FinishWriteSentence'
}
