/**
 * main-srv
 * primary server for app
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export enum SentenceSearchType {
    AllKnown = 'all-known',
    Nplusone = 'nplusone',
    Nplus2 = 'nplus2',
    Unrestricted = 'unrestricted'
}
