import { useQuery } from "@tanstack/react-query"
import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { TextStyle } from "../App"
import { Modal } from "../Components/Modal"
import { ContentTile, StyledButton } from "../Components/ModeTile"
import { DictionaryQueryTile } from "../Displays/ProcessedTextDisplay"
import { ColumnLayout, ControllerModeContainer, InnerTile, RowLayout } from "../Styles/Styles"
import { TextHelperModal } from "./TextHelperView"
import { ApiContext } from "../Contexts/Api/ApiContext"
import { UnwrapApiCall } from "../Contexts/Api/DefaultApiContext"
import { Query, UnifiedLookupQueryUnit } from "../api"
import { ItemDataDisplay } from "./ItemDataView"
import Column from "antd/es/table/Column"
import dayjs from "dayjs"
import { ZhUnitTestInterfaceConfig } from "../Config/Language/Zh/Config/UnitTestInterfaceConfig"
import { Data } from "../Types/Interfaces"
import { Collapse } from "../Components/Collapse"
import { PracticeItemView } from "./PracticeView"
import { ItemExamplesView } from "../Components/ItemExamplesView"
import { StudyIcon } from "../Icons/StudyIcon"
import { NewLearnView } from "./NewLearnView"
import { ZhItemTypeTestConfiguration } from "../Config/Language/Zh/Config/ItemTypeTestConfig"

const StyledInput = styled.input`
height: 64px ;
font-size: 2rem;
padding-left: 24px;
`

export const DictionaryModal: React.FC<{ Query: string, OnClose: () => any }> = props => {
    return <>
        <Modal OnClose={props.OnClose}>
            <LoadDictionaryQuery query={props.Query} />
        </Modal>
    </>
}

export const LoadDictionaryQuery: React.FC<{ query: string }> = props => {
    const api = useContext(ApiContext)
    const topRef = React.createRef<HTMLDivElement>();

    const { isPending, error, data } = useQuery({
        queryKey: [`query#${props.query}`],
        queryFn: () => {
            return api.UnifiedLookup(props.query).then(UnwrapApiCall)
        }
    })

    useEffect(() => {
        // 👇️ Scroll to the bottom every time messages change
        topRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [data]);


    if (isPending) return <>Loading...</>
    if (error) return <>An error has occurred: {error.message}</>
    if (data == undefined) return <>An error has occurred: { }</>

    const response = data!!;

    return <>
        <ContentTile ref={topRef}>
            <ColumnLayout>
                <TextStyle size={3}>{response.query}</TextStyle>
                <div style={{ borderBottom: '1px solid #0000003f' }}></div>

                <RowLayout>
                    {response.segments.map(s => {
                        return <>
                            {s.item != undefined && <UnifiedItemDisplay Data={s} />}
                        </>
                    })}
                </RowLayout>

                <div style={{ borderBottom: '1px solid #0000003f' }}></div>
                <RowLayout>
                    {response.bases.map(b => {
                        return <>
                            {b.item != undefined && <UnifiedItemDisplay Data={b} />}
                        </>

                    })}
                </RowLayout>
                {/* <ColumnLayout>
                    {response.results == undefined || response.results.length == 0 && <>
                        <TextStyle>No results</TextStyle>
                        <TargetDefnWrapper HideText={true} key={0} text={response.query} />
                    </>}
                    <TextStyle>{response.results?.length} results</TextStyle>
                </ColumnLayout>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 24 }}>
                    {
                        response.results?.map(r => {
                            return <>
                                <InnerTile style={{ padding: 12, justifyContent: 'center' }}>
                                    <ColumnLayout>
                                        <TextStyle>{r.reading}</TextStyle>
                                        {r.definition.map(d => {
                                            return <>
                                                <TextStyle>{d.primaryLangDefn}</TextStyle>
                                                {d.targetLangDefn != undefined && <TargetDefnWrapper key={0} text={d.targetLangDefn} />}
                                            </>
                                        })}
                                    </ColumnLayout>
                                </InnerTile>
                            </>
                        })
                    }
                </div> */}
            </ColumnLayout>
        </ContentTile>
    </>

}

export const UnifiedItemDisplay: React.FC<Data<UnifiedLookupQueryUnit>> = props => {

    const isIntroduced = props.Data.history.some(h => h.introduced == true)
    const itemUnit = props.Data.history[0]?.unitId

    const hasDictionary = props.Data.dictionary.results != undefined && props.Data.dictionary.results.length > 0;
    const firstDictionaryEntry = props.Data.dictionary.results!![0]
    const synthedDefinitionEntry = props.Data.dictionary.results!!.map(r => r.definition[0]?.primaryLangDefn).join(", ")
    const synthedReading = props.Data.dictionary.results!!.map(r => r.reading).join(", ")

    const [showDetailed, setShowDetailed] = useState(false)
    const [practiceView, setPracticeView] = useState(false)
    const [examplesView, setExamplesView] = useState(false)
    const [showStudyView, setShowStudyView] = useState(false)

    return <>
        {
            showStudyView && <>
                <Modal OnClose={() => setShowStudyView(false)}>
                    <ContentTile>
                        <NewLearnView OnExit={() => setShowStudyView(false)}
                            SetMenu={() => { }}
                            Data={{
                                NextUnits: {
                                    New: props.Data.history.filter(h => !h.introduced && h.testDefinitionId != 2),
                                    Review: []
                                },
                                ItemTypeTests: ZhItemTypeTestConfiguration,
                                ItemDataRecord: { [props.Data.key]: props.Data.item!! },
                                UnitTestInterfaces: ZhUnitTestInterfaceConfig,
                                Params: {
                                    IntroduceNew: true,
                                    IntroduceCount: 1,
                                    RoundSize: props.Data.history.filter(h => !h.introduced && h.testDefinitionId != 2).length,
                                    PromoteOnNth: 1,
                                    FailOnNth: 0,
                                    ShowUntilCorrect: true,
                                    ShuffleOnIncorrect: true,
                                    SaveResults: true,
                                    TimeLimit: 0,
                                    TestDefinitions: [],
                                    OrderRound: (item) => item
                                },
                            }} />
                    </ContentTile>
                </Modal>
            </>
        }
        {
            practiceView && <>
                <Modal OnClose={() => { setPracticeView(false) }}>

                    <PracticeItemView Data={{ Item: props.Data.item!!, TestDefinitions: ZhUnitTestInterfaceConfig[itemUnit] }} />
                </Modal>
            </>
        }
        {
            examplesView && <>
                <Modal OnClose={() => { setExamplesView(false) }}>
                    <ItemExamplesView Data={{ Item: props.Data.item!! }} />
                </Modal>
            </>
        }
        {
            showDetailed && !practiceView && !examplesView && <>
                <Modal OnClose={() => setShowDetailed(false)}>
                    <ContentTile>
                        <RowLayout style={{ alignItems: 'center' }}>
                            <TextStyle size={3} weight="bold" colour={isIntroduced ? 'black' : '#0000003f'}>{firstDictionaryEntry.unit}</TextStyle>
                            <TextStyle size={1} weight="bold">{synthedReading}</TextStyle>
                            <TextStyle size={1} weight="bold">{synthedDefinitionEntry}</TextStyle>
                        </RowLayout>
                        {props.Data.item != undefined && <RowLayout>
                            {Object.keys(props.Data.item?.metadata).map(k => {
                                return <RowLayout style={{ width: 'fit-content' }}>
                                    <TextStyle size={1} weight="bold">{k}</TextStyle>
                                    <TextStyle size={1}>{props.Data.item?.metadata[k]}</TextStyle>
                                </RowLayout>
                            })}
                        </RowLayout>}
                        {props.Data.dictionary.results?.map(r => {
                            return <RowLayout>
                                {/* <TextStyle size={1} weight="bold">{r.reading}</TextStyle> */}
                                <ul style={{ marginTop: 12, paddingTop: 0, listStyleType: 'circle' }}>
                                    {r.definition.map(d => {
                                        return <li>
                                            <TextStyle size={1} >{d.primaryLangDefn}</TextStyle>
                                            <TextStyle size={1} >{d.targetLangDefn}</TextStyle>
                                        </li>
                                    })}
                                </ul>

                            </RowLayout>
                        })}
                        <RowLayout>
                            <Collapse Collapsed={true} Label="Test History">
                                {props.Data.history.map(ih => {
                                    return <div>

                                        <TextStyle size={1} weight="bold">{ZhUnitTestInterfaceConfig[ih.unitId][ih.testDefinitionId].Label}</TextStyle>
                                        <TextStyle size={1}>Last Seen: {dayjs(ih.lastSeen).toString()}</TextStyle>
                                        <TextStyle size={1}>Due: {dayjs(ih.due).toString()}</TextStyle>
                                        <TextStyle size={1}>Difficulty: {ih.difficulty}</TextStyle>
                                        <TextStyle size={1}>Stability: {ih.stability}</TextStyle>
                                        <TextStyle size={1}>Lapses: {ih.lapses}</TextStyle>
                                        <TextStyle size={1}>Reps: {ih.reps}</TextStyle>
                                        <TextStyle size={1}>Learning State: {ih.learningState}</TextStyle>

                                    </div>
                                })}
                            </Collapse>
                        </RowLayout>
                        <RowLayout>
                            <StyledButton onClick={() => setExamplesView(true)}>Examples</StyledButton>
                            <StyledButton onClick={() => setPracticeView(true)}>Practice</StyledButton>
                        </RowLayout>
                    </ContentTile>
                </Modal>
            </>
        }
        <ContentTile onClick={() => setShowDetailed(!showDetailed)}>
            <ColumnLayout>
                {hasDictionary && <>

                    <div onClick={(e) => {
                        e.stopPropagation();
                        setShowStudyView(true);
                    }} style={{ zIndex: 50, marginRight: 0, marginLeft: 'auto' }}>
                        <StudyIcon />
                    </div>
                    <RowLayout style={{ marginTop: -48, alignItems: 'center' }}>
                        <TextStyle size={3} weight="bold" colour={isIntroduced ? 'black' : '#0000003f'}>{firstDictionaryEntry.unit}</TextStyle>
                        <TextStyle size={1} weight="bold">{synthedReading}</TextStyle>
                        <TextStyle size={1} weight="bold">{synthedDefinitionEntry}</TextStyle>
                    </RowLayout>
                </>}
                {/* 
                <TextStyle size={1} weight="bold">{props.Data.item?.properties["PrimaryDefinition"]}</TextStyle>
                <RowLayout>
                    {Object.keys(props.Data.item?.metadata).map(k => {
                        return <RowLayout style={{ width: 'fit-content' }}>
                            <TextStyle size={1} weight="bold">{k}</TextStyle>
                            <TextStyle size={1}>{props.Data.item?.metadata[k]}</TextStyle>
                        </RowLayout>
                    })}
                </RowLayout> */}






            </ColumnLayout>
        </ContentTile >
    </>
}

export const DictionaryView: React.FC<{ query?: string }> = props => {

    const [query, setQuery] = useState(props.query ? props.query : "")

    const [search, setSearch] = useState(false)

    const Search = async () => {
        // console.log(query)

        if (query == "" || query == undefined) {
            setSearch(false);
            return;

        } else {
            setSearch(true);
        }

    }


    return <>
        <ContentTile>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 24 }}>
                <StyledInput style={{ width: 'calc(100% - 24px)' }} onChange={(e) => { setQuery(e.target.value) }} type="text"></StyledInput>
                <StyledButton onClick={Search}>Search</StyledButton>
            </div>
        </ContentTile>
        {search && <LoadDictionaryQuery query={query} />}

    </>
}

export const TargetDefnWrapper: React.FC<{ HideText?: boolean, text: string }> = props => {
    const [showTextHelper, setShowTextHelper] = useState(false)

    return <>
        {(props.HideText == undefined || !props.HideText) && <TextStyle>{props.text}</TextStyle>}
        <StyledButton onClick={() => setShowTextHelper(true)}>Assist</StyledButton>
        {showTextHelper && <TextHelperModal Ref={''} Text={props.text} OnClose={() => setShowTextHelper(false)} />}
    </>
}