/**
 * main-srv
 * primary server for app
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { KeyRecordStats } from '../models/KeyRecordStats';
import { HttpFile } from '../http/http';

/**
* Construct a type with a set of properties K of type T
*/
export class RecordSentenceKeyLastSeenKeyRecordStats {
    'today': KeyRecordStats;
    'yesterday': KeyRecordStats;
    'thisPeriod': KeyRecordStats;
    'lastPeriod': KeyRecordStats;
    'anyTime': KeyRecordStats;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "today",
            "baseName": "today",
            "type": "KeyRecordStats",
            "format": ""
        },
        {
            "name": "yesterday",
            "baseName": "yesterday",
            "type": "KeyRecordStats",
            "format": ""
        },
        {
            "name": "thisPeriod",
            "baseName": "this-period",
            "type": "KeyRecordStats",
            "format": ""
        },
        {
            "name": "lastPeriod",
            "baseName": "last-period",
            "type": "KeyRecordStats",
            "format": ""
        },
        {
            "name": "anyTime",
            "baseName": "any-time",
            "type": "KeyRecordStats",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return RecordSentenceKeyLastSeenKeyRecordStats.attributeTypeMap;
    }

    public constructor() {
    }
}

